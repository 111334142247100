<template>
  <article id="wrap">
    <AppHeader theme="green">入力内容確認</AppHeader>

    <section class="" style="margin-bottom: 300px;">

      <div class="ta_c txt_16 bold mt_20 mb_10">商品情報入力確認</div>

      <table class="table01">
        <tbody><tr>
          <th>商品名</th>
          <td>{{ _name }}</td>
        </tr>
        <tr>
          <th>商品画像</th>
          <td>
            <ul class="file_upload confirm">
              <template v-for="n in 4">
                <li :key="n">
                  <img :src="photos[n-1]" alt="商品画像" v-if="photos[n-1]">
<!--                    <img src="@/assets/img/mypage/item_photo.jpg" alt="商品画像" v-else>-->
                </li>
              </template>
            </ul>
          </td>
        </tr>
        <tr>
          <th>販売価格</th>
          <td>{{ price }}円(税込)</td>
        </tr>
        <tr>
          <th>商品個数</th>
          <td>{{ value }}</td>
        </tr>
        <tr>
          <th>商品説明</th>
          <td>{{ detail }}</td>
        </tr>
        </tbody>
        <section class="contents">
          <div class="bt_form"><button type="submit" value="send" @click="send">送信</button></div>
        </section>
      </table>
    </section>
    <hr class="item_hr">

    <AppFooter fixed></AppFooter>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  components: { AppHeader, AppFooter },
  props: {
    _name: String,
    price: Number,
    value: Number,
    detail: String,
    accept: Boolean,
    photos: Array
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      // const response = await this.$http.get(`artists/${this.$route.params.artist}`);
      // this.artist = response.data;
    },
    async send () {
      this.$store.commit('setLoading');
      await this.$http.post('items', {
        artist_id: this.$store.state.loginUser.artist_id,
        name: this._name, price: this.price, value: this.value, detail: this.detail, photos: this.photos
      });
      this.$store.commit('resetLoading');
      this.$router.push({name: 'item-create-send'});
    }
  }
}
</script>

<style scoped>
.file-button {
  display: none;
}
ul.file_upload li img {
  cursor: pointer;
}
</style>